import React from "react"
import classNames from "classnames"
import { graphql } from "gatsby"
import TheaterVideo from "@components/Theater/TheaterVideo"
import Layout from "../components/layout"
import GetStarted from "../components/CTA/GetStarted"
import { CallToAction } from "../components/CTA"
import ReviewCarousel from "../components/carousel/ReviewCarousel"
import SEO from "../components/seo"
import { sortReviewsByDate, getPostObj, pluralizeName } from "../utils/utils"
import { Image, Text } from "../components/Core"
import { Button, ButtonGroup } from "../components/Button"

const SingleReview = ({ data, pageContext, location }) => {
  const post = data.singleReview.nodes[0]
  const { metaTitle, metaDescription, language } = pageContext

  const getStartedData = data.allTemplateSettingsJson.nodes[0].getStarted
  const getStartedDataEs =
    data.allTemplateSettingsJson.nodes[0].getStartedSpanish

  const reviewTitle = post.title
  const reviewPool = data.relatedReviews.nodes
  let referrerPool = data.relatedReviewsProviders.nodes

  let relatedReviews = getRelatedReviews(post, reviewPool)

  relatedReviews = sortReviewsByDate(relatedReviews)
  referrerPool = sortReviewsByDate(referrerPool)

  function getRelatedReviews(singleReview, allReviews) {
    const thisReviewProcedures = getReviewProceduresNames(singleReview)

    // Get an array of the patient's procedures
    function getReviewProceduresNames(review) {
      const thisReviewProcedures = []

      for (let i = 0; i < review.proceduresDone.length; i++) {
        thisReviewProcedures.push(
          review.proceduresDone[i].procedureDone.procedureName
        )
      }
      return thisReviewProcedures
    }

    const reviewPoolWithProceduresNamesArray =
      addProceduresDoneArrayTo(allReviews)

    // Add array of procedures done names to all review objects
    function addProceduresDoneArrayTo(reviews) {
      const reviewPoolWithProceduresNamesArray = []
      // Make an array of just the names of the procedures done
      for (let i = 0; i < reviews.length; i++) {
        const relatedProceduresDone = []
        for (let j = 0; j < reviews[i].proceduresDone.length; j++) {
          relatedProceduresDone.push(
            reviews[i].proceduresDone[j].procedureDone.procedureName
          )
        }
        const patientInfo = reviews[i]
        patientInfo.allProceduresJustNames = relatedProceduresDone
        reviewPoolWithProceduresNamesArray.push(patientInfo)
      }
      return reviewPoolWithProceduresNamesArray
    }

    const relatedReviews = getReviewsWithAnyMatchingProcedures(
      thisReviewProcedures,
      reviewPoolWithProceduresNamesArray
    )

    // Compare patients to see if any of their procedures done match, if so, add to array and return it
    function getReviewsWithAnyMatchingProcedures(
      thisReviewProcedureNamesArray,
      allReviewsWithProcedureNamesArray
    ) {
      const relatedReviews = []
      // Compares the single review's procedures done names with all the reviews' procedures done names, then pushes matches into another array
      let found = false

      for (let i = 0; i < allReviewsWithProcedureNamesArray.length; i++) {
        if (reviewTitle !== allReviewsWithProcedureNamesArray[i].title) {
          found = false

          found = thisReviewProcedureNamesArray.some(r =>
            allReviewsWithProcedureNamesArray[
              i
            ].allProceduresJustNames.includes(r)
          )
          if (found === true) {
            relatedReviews.push(allReviewsWithProcedureNamesArray[i])
            continue
          }
        }
      }
      return relatedReviews
    }

    return relatedReviews
  }

  const relatedProcedures = post.proceduresDone.map((procedure, i) => {
    let buttonText = procedure.procedureDone.procedureName

    // Full-Arch
    // Impacted Teeth
    // Cleft Treatment
    // DO
    // Jaw Surgery
    // TMJ Disorders
    // Extractions
    // Wisdom Teeth

    if (procedure.procedureDone.procedureShortName) {
      buttonText = procedure.procedureDone.procedureShortName
    }

    return (
      <Button
        className={`contained button-number-${i}`}
        key={procedure.procedureDone.procedureUrl}
        buttonText={buttonText}
        href={`/${procedure.procedureDone.procedureUrl}`}
      />
    )
  })

  const showOtherReviews = function () {
    if (post.reviewType === "Patient") {
      return hasOtherPatientReviews()
    }
    if (post.reviewType === "Provider") {
      return hasOtherReferrerReviews()
    }
  }

  var hasOtherPatientReviews = function () {
    if (relatedReviews.length > 0) {
      return true
    }
    return false
  }
  var hasOtherReferrerReviews = function () {
    if (referrerPool.length > 0) {
      return true
    }
    return false
  }

  const reviewCarouselHeading = classNames({
    "review-carousel-heading": true
  })

  let backUrl = ""

  if (data.allUniquePagesJson?.nodes[0]?.title) {
    backUrl = `/${data.allUniquePagesJson.nodes[0].title}`
  }

  const esCTAClass =
    language === "es" ? "patient-review-cta-es" : "patient-review-cta"

  return (
    <Layout language={language}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        lang={language}
      />
      <section
        className="com-content single-review"
        style={{ paddingBottom: 0 }}>
        <Button
          buttonText={language === "es" ? "ATRÁS" : "BACK"}
          goBack
          href={language === "es" ? "/es/historias-de-pacientes/" : backUrl}
        />
        <div className="columns review-intro-text">
          <div className="column is-5" />
          <div className="column">
            <h1 style={{ marginTop: 0 }}>{post.heading}</h1>
            <div>
              <h6 className="video-review-head">
                {language === "es" ? "Revisión de Video" : "Video Review"}
              </h6>

              <span className="review-small-text">
                {post.language.toUpperCase()}
              </span>
            </div>
            <Text as="p" text={post.subheading} />
            <TheaterVideo
              videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
              playing
              hasWatchVideo
              language={language}
              buttonClass="contained"
            />
          </div>
          <div className="column is-4" />
        </div>

        {post.youtube && (
          <div className="columns review-video-section">
            <div className="column is-1" />
            <div className="column has-text-centered">
              <TheaterVideo
                videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                playing
                language={language}
                hasWatchVideo={false}>
                <Image
                  useAR
                  publicId={post.mainPhotoPublicId}
                  responsiveUseBreakpoints="true"
                  className="theater-image"
                />
              </TheaterVideo>
            </div>
            <div className="column is-1" />
          </div>
        )}

        {language !== "es" && (
          <div className="columns review-quote-section">
            <div className="column is-4" />
            <div className="column">
              <h3>{pluralizeName(post.reviewerName)} Story</h3>
              {language === "en" && (
                <p className="review-quote-text">
                  "<Text as="span" text={post.quote} />"
                </p>
              )}
              {post.reviewType !== "Provider" &&
                post.proceduresDone.length > 0 && (
                  <div className="review-related-procedures-buttons">
                    <h5 className="has-text-centered-mobile">
                      Learn More About
                    </h5>
                    <ButtonGroup alignLeft noReverse>
                      {relatedProcedures}
                    </ButtonGroup>
                  </div>
                )}
            </div>
            <div className="column is-4" />
          </div>
        )}

        {showOtherReviews() && language !== "es" && (
          <>
            <div className="columns">
              <div className="column is-4" />
              <div className="column">
                <h3 className={reviewCarouselHeading}>
                  Watch Other Reviews Like {pluralizeName(post.reviewerName)}
                </h3>
              </div>
            </div>
            <div
              className="carousel-section-match mb-3"
              style={{ paddingBottom: "0" }}>
              <ReviewCarousel
                id="singleReview"
                noShuffle
                relatedReviews={
                  post.reviewType === "Patient" ? relatedReviews : referrerPool
                }
              />
            </div>
          </>
        )}
      </section>
      <CallToAction
        colorBack
        heading={
          language === "es" ? getStartedDataEs.heading : getStartedData.heading
        }
        blurb={
          language === "es" ? getStartedDataEs.blurb : getStartedData.blurb
        }
        buttons={
          language === "es" ? getStartedDataEs.buttons : getStartedData.buttons
        }
      />
    </Layout>
  )
}

export default SingleReview

export const query = graphql`
  query ($id: String!, $language: String!, $title: String!) {
    procedureQueryPage: allUniquePagesJson(
      filter: { template: { eq: "procedures" }, language: { eq: $language } }
    ) {
      nodes {
        title
      }
    }
    allUniquePagesJson(
      filter: {
        template: { eq: "patient-stories" }
        language: { eq: $language }
      }
    ) {
      nodes {
        title
      }
    }
    allTemplateSettingsJson(filter: { templateType: { eq: "single-review" } }) {
      nodes {
        getStarted {
          blurb
          buttonText
          heading
          href
          subheading
          buttons {
            button {
              appearance
              destination
              href
              buttonText
            }
          }
        }
        getStartedSpanish {
          blurb
          buttonText
          heading
          href
          subheading
          buttons {
            button {
              appearance
              destination
              href
              buttonText
            }
          }
        }
      }
    }
    singleReview: allReviewsJson(filter: { id: { eq: $id } }) {
      nodes {
        id
        title
        metaTitle
        metaDescription
        mainProcedureDone
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
            otherProcedure
            procedureShortName
          }
        }
        heading
        quote
        language
        reviewType
        reviewerName
        subheading
        thumbnailPublicId
        mainPhotoPublicId
        youtube
      }
    }
    relatedReviews: allReviewsJson(
      filter: {
        reviewType: { eq: "Patient" }
        language: { eq: "en" }
        title: { ne: $title }
      }
    ) {
      nodes {
        thumbnailPublicId
        title
        reviewerName
        reviewType
        mainProcedureDone
        otherProcedureName
        monthYear {
          month
          year
        }
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
          }
        }
      }
    }
    relatedReviewsProviders: allReviewsJson(
      filter: {
        reviewType: { eq: "Provider" }
        language: { eq: "en" }
        title: { ne: $title }
      }
    ) {
      nodes {
        thumbnailPublicId
        title
        reviewerName
        reviewType
        mainProcedureDone
        monthYear {
          month
          year
        }
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
          }
        }
      }
    }
  }
`
